import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Grid,
  Tab,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  Portal,
} from "@material-ui/core";
import { InfoOutlined, ArrowForwardIos, Star } from "@material-ui/icons";
import DashboardKitchenController, {
  BusinessView,
  Props,
} from "./DashboardKitchenController.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { customerReview, emptyData, report } from "./assets";
import CustomTypographyIndex from "../../../components/src/CustomTypographyIndex";
import CustomTabs from "../../../components/src/CustomTabs";
import CustomTable from "../../../components/src/CustomTable";
import CustomPagination from "../../../components/src/CustomPagination";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";

const styles = (theme: any) =>
  createStyles({
    container: {
      "& *": {
        boxSizing: "border-box",
      },

      "&.rtl": {
        direction: "rtl",
      },
    },

    tabs: {
      paddingBottom: "0 !important",
      marginBottom: -8,
    },

    block: {
      padding: 12,
      borderRadius: 8,
      border: "1px solid #EDEDED",
      minHeight: 300,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    blockHeading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    blockDescription: {
      marginTop: 8,
    },

    blockHeadingLink: {
      fontWeight: 600,
    },

    business: {
      marginTop: 10,
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },

    businessSummary: {
      display: "flex",
      justifyContent: "flex-end",

      "&.rtl": {
        justifyContent: "initial",
      },
    },

    businessSummaryItem: {
      textAlign: "right",

      "& + &": {
        marginLeft: 34,
      },
    },

    businessSummaryItemIndex: {
      fontSize: 24,
      lineHeight: "29.05px",
    },

    businessSummaryTable: {
      marginTop: 18,
    },

    businessSummaryBottom: {
      marginTop: "auto",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",

      [theme.breakpoints.down("xs")]: {
        gap: 10,
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "initial",
      },
    },

    opsMonitor: {
      marginTop: 10,
    },

    opsMonitorItem: {
      display: "flex",
      alignItems: "center",
      padding: 12,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      minHeight: 78,
      gap: 10,

      "& + &": {
        marginTop: 10,
      },
    },

    opsMonitorItemIndex: {
      minWidth: 34,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    opsMonitorItemTitle: {
      display: "flex",
      alignItems: "center",
    },

    opsMonitorItemDescription: {
      marginTop: 5,
    },

    opsMonitorItemName: {
      display: "flex",
      alignItems: "center",
    },

    opsMonitorItemIcon: {
      marginLeft: "auto",

      "&.rtl": {
        transform: "scaleX(-1)",
        marginLeft: "initial",
        marginRight: "auto",
      },
    },

    operation: {
      marginTop: 8,
      flex: 1,
    },

    operationBlock: {
      borderRadius: 8,
      padding: 12,
      backgroundColor: "#EDEDED",
      minHeight: 103,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    operationHeading: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },

    operationInfo: {
      display: "flex",
      alignItems: "center",
      marginTop: 5,
    },

    operationInfoIcon: {
      fontSize: 18,
      marginLeft: 5,
      cursor: "pointer",
    },

    operationDuration: {
      marginTop: "auto",
    },

    customerReview: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },

    customerReviewBackground: {
      width: 155,
      height: "auto",
    },

    customerReviewDescription: {
      marginTop: 4,
      maxWidth: 381,
      textAlign: "center",
    },

    noDataText: {
      marginTop: 15,
    },

    emptyBox: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },

    businessSummaryInner: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      rowGap: 13
    }
  });
// Customizable Area End

export class DashboardKitchen extends DashboardKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;
    const {
      businessTab,
      businessView,
      restaurantBusinessData,
      liveOpsMonitor,
      loading,
      snackbar,
      totalOrders,
      totalSales,
      pagination,
      operationHealthData
    } = this.state;

    const rtl = i18n.language === "ar";

    const { offlineOutlets, cancelledOrders, delayedOrders, rating } =
      liveOpsMonitor;

    const liveOpsMonitorData = {
      offlineOutlets: {
        name: t("Offline outlets"),
        time: t("Current"),
        number: offlineOutlets.number,
        isGood: offlineOutlets.isGood,
      },

      cancelledOrders: {
        name: t("Cancelled orders"),
        time: t("Today"),
        number: cancelledOrders.number,
        isGood: cancelledOrders.isGood,
      },

      delayedOrders: {
        name: t("Delayed orders"),
        time: t("Today"),
        number: delayedOrders.number,
        isGood: delayedOrders.isGood,
      },

      rating: {
        name: (
          <>
            1 <Star /> {t("ratings")}
          </>
        ),
        time: t("Today"),
        number: rating.number,
        isGood: rating.isGood,
      },
    };

    const operationsHealthList = {
      vendorCancellations: {
        name: operationHealthData.vendorCancellations.name,
        head: (
          <>
            <CustomTypography variant="subtitle1">
              {operationHealthData.vendorCancellations.title}
            </CustomTypography>

            <CustomTypographyIndex variant="subtitle1" isGood>
              {operationHealthData.vendorCancellations.data}
            </CustomTypographyIndex>
          </>
        ),
      },

      offlineTime: {
        name: operationHealthData.offlineTime.data,
        head: (
          <>
            <CustomTypography variant="subtitle1">
              {operationHealthData.offlineTime.title}
            </CustomTypography>

            <CustomTypographyIndex variant="subtitle1" isIncrease>
              {operationHealthData.offlineTime.data}
            </CustomTypographyIndex>
          </>
        ),
      },

      avgPreparationTime: {
        name: operationHealthData.avgPreparationTime.name,
        head: (
          <>
            <CustomTypography variant="subtitle1">
              {operationHealthData.avgPreparationTime.title}
            </CustomTypography>

            <CustomTypographyIndex variant="subtitle1" isIncrease>
              {operationHealthData.avgPreparationTime.data}
            </CustomTypographyIndex>
          </>
        ),
      },

      orderDelayRate: {
        name: operationHealthData.orderDelayRate.name,
        head: (
          <>
            <CustomTypographyIndex variant="subtitle1" isGood>
              {operationHealthData.orderDelayRate.title}
            </CustomTypographyIndex>
          </>
        ),
      },
    };

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.tabs}
          >
            <CustomTabs
              variant="scrollable"
              scrollButtons="auto"
              value={businessTab}
            >
              {this.businessSummaryTabs.map((tab) => {
                return (
                  <Tab
                    key={tab.id}
                    label={t(tab.name)}
                    value={tab.value}
                    onClick={() => this.changeBusinessTab(tab.value)}
                  />
                );
              })}
            </CustomTabs>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  {t("Business Summary")}
                </CustomTypography>
                <CustomTypography
                  variant="button"
                  className={classes.blockHeadingLink}
                >
                  {t("More in Reports")}
                </CustomTypography>
              </Box>
              <CustomTypography
                className={classes.blockDescription}
                variant="body1"
              >
                {t("Outlet View")}
              </CustomTypography>

              <Box className={classes.business}>
                <Box
                  className={clsx(classes.businessSummary, {
                    ["rtl"]: rtl,
                  })}
                >
                  <Box className={classes.businessSummaryItem}>
                    <CustomTypography
                      variant="subtitle1"
                      className={classes.businessSummaryItemIndex}
                    >
                      {totalOrders}
                    </CustomTypography>
                    <CustomTypography variant="body1">
                      {t("Total Orders")}
                    </CustomTypography>
                  </Box>

                  <Box className={classes.businessSummaryItem}>
                    <CustomTypography
                      variant="subtitle1"
                      className={classes.businessSummaryItemIndex}
                    >
                      {t("SAR {{number}}", { number: totalSales })}
                    </CustomTypography>
                    <CustomTypography variant="body1">
                      {t("Total Sales")}
                    </CustomTypography>
                  </Box>
                </Box>

                {restaurantBusinessData.length > 0 ? (
                  <Box className={classes.businessSummaryInner}>
                    <Box className={classes.businessSummaryTable}>
                      <TableContainer>
                        <CustomTable>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("Restaurant name")}</TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                {t("Order Count")}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <TableSortLabel active direction="desc">
                                  {t("Sales")}
                                </TableSortLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {restaurantBusinessData.map((data) => (
                              <TableRow key={data.id}>
                                <TableCell>{data.name}</TableCell>
                                <TableCell
                                  style={{
                                    fontFamily: "Inter",
                                    textAlign: "right",
                                  }}
                                >
                                  {data.orderCount}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontFamily: "Inter",
                                    textAlign: "right",
                                  }}
                                >
                                  {t("SAR {{number}}", { number: data.sales })}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </CustomTable>
                      </TableContainer>
                    </Box>

                    <Box className={classes.businessSummaryBottom}>
                      <CustomTabs rounded value={businessView}>
                        <Tab
                          label={t("Outlet View")}
                          value={BusinessView.OutletView}
                          onClick={() =>
                            this.changeBusinessView(BusinessView.OutletView)
                          }
                        />

                        <Tab
                          label={t("Chart View")}
                          value={BusinessView.ChartView}
                          onClick={() =>
                            this.changeBusinessView(BusinessView.ChartView)
                          }
                        />
                      </CustomTabs>

                      <CustomPagination
                        count={pagination.totalPages}
                        page={pagination.page}
                        total={pagination.totalCount}
                        limit={pagination.limit}
                        onChange={(_, page) => this.changePage(page)}
                        variant="text"
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.emptyBox}>
                    <img src={emptyData} />
                    <CustomTypography className={classes.noDataText}>
                      {t("No Orders Generated")}
                    </CustomTypography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className={classes.block}>
              <CustomTypography variant="h5">
                {t("Live Ops Monitor")}
              </CustomTypography>

              <CustomTypography
                className={classes.blockDescription}
                variant="body1"
              >
                {t("We found some ongoing issues for your outlets")}
              </CustomTypography>

              <Box className={classes.opsMonitor}>
                {Object.keys(liveOpsMonitorData).map((key: string) => {
                  const monitorData =
                    liveOpsMonitorData[key as keyof typeof liveOpsMonitorData];

                  return (
                    <Box className={classes.opsMonitorItem} key={key}>
                      <CustomTypographyIndex
                        className={classes.opsMonitorItemIndex}
                        variant="h2"
                        hideIcon
                        isGood={monitorData.isGood}
                      >
                        {monitorData.number}
                      </CustomTypographyIndex>

                      <Box className={classes.opsMonitorItemContent}>
                        <CustomTypography
                          variant="subtitle2"
                          className={classes.opsMonitorItemName}
                        >
                          {monitorData.name}
                        </CustomTypography>

                        <CustomTypography
                          variant="body1"
                          className={classes.opsMonitorItemDescription}
                        >
                          {monitorData.time}
                        </CustomTypography>
                      </Box>

                      <ArrowForwardIos
                        className={clsx(classes.opsMonitorItemIcon, {
                          ["rtl"]: rtl,
                        })}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  {t("Operations Health")}
                </CustomTypography>

                <CustomTypography
                  variant="button"
                  className={classes.blockHeadingLink}
                >
                  {t("More in Reports")}
                </CustomTypography>
              </Box>

              <Grid className={classes.operation} container spacing={3}>
                {!operationHealthData.vendorCancellations.name ? (
                  <Box className={classes.emptyBox}>
                    <img src={report} />
                    <CustomTypography className={classes.noDataText}>
                      {t("No Report Generated")}
                    </CustomTypography>
                  </Box>
                ) : (
                  Object.keys(operationsHealthList).map((key: string) => {
                    const operationData =
                      operationsHealthList[
                        key as keyof typeof operationsHealthList
                      ];

                    return (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={key}>
                        <Box className={classes.operationBlock}>
                          <Box className={classes.operationHeading}>
                            {operationData.head}
                          </Box>

                          <Box className={classes.operationInfo}>
                            <CustomTypography variant="body1">
                              {operationData.name}
                            </CustomTypography>

                            <InfoOutlined
                              className={classes.operationInfoIcon}
                            />
                          </Box>

                          <CustomTypography
                            variant="caption"
                            className={classes.operationDuration}
                          >
                            {t("Last {{number}} days", { number: 7 })}
                          </CustomTypography>
                        </Box>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid xl={6} xs={12} sm={12} md={6} lg={6} item>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  {t("Customer Reviews")}
                </CustomTypography>
                <CustomTypography
                  className={classes.blockHeadingLink}
                  variant="button"
                >
                  {t("More in Reviews")}
                </CustomTypography>
              </Box>
              <Box className={classes.customerReview}>
                <img
                  className={classes.customerReviewBackground}
                  src={customerReview}
                  alt="Customer Review"
                />
                <CustomTypography variant="h6">
                  {t("No reviews yet")}
                </CustomTypography>
                <CustomTypography
                  className={classes.customerReviewDescription}
                  variant="body1"
                >
                  {t(
                    "You haven't received any new reviews recently. They would appear here, when you receive them"
                  )}
                </CustomTypography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(DashboardKitchen));
// Customizable Area End
