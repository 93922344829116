// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import React from "react";

import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { AdminPromocodesAdvertisingStatus } from "../../../components/src/AdminPromocodesAdvertisingStatusTag.web";
import {
  AdminPromocodesAdvertisingDetailFormDialogProps,
  AdminPromocodesAdvertising,
} from "../../../components/src/AdminPromocodesAdvertisingDetailFormDialog.web";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;

  advertisingList: AdminPromocodesAdvertising[];

  timeFilter: number;

  restaurantFilter: string;

  requestIdSearch: string;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  adminAdvertisingDetailDialog: AdminPromocodesAdvertisingDetailFormDialogProps;
}

interface SS {}

export default class AdminPromocodesAdvertisingController extends BlockComponent<
  Props,
  S,
  SS
> {
  inputRef: React.RefObject<HTMLInputElement>;

  timeOptions = [
    {
      id: uuidv4(),
      name: "Today",
      value: 1,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: 2,
    },

    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },

    {
      id: uuidv4(),
      name: "Last 30 days",
      value: 30,
    },

    {
      id: uuidv4(),
      name: "All",
      value: "",
    },
  ];

  restaurantOptions = [
    {
      id: uuidv4(),
      name: "Restaurant",
      value: "restaurant",
    },

    {
      id: uuidv4(),
      name: "Branch",
      value: "branch",
    },
  ];

  adminAdvertisingDetailDialogDefaultValue: AdminPromocodesAdvertisingDetailFormDialogProps =
    {
      open: false,
      loading: false,
      form: {
        requestId: "",
        restaurantName: "",
        startDate: new Date(),
        endDate: new Date(),
        engagement: {
          likes: {
            number: 0,
            type: "like",
          },
          views: 0,
        },
        revenue: 0,
        status: AdminPromocodesAdvertisingStatus.Active,
      },
    };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,

      advertisingList: [],

      timeFilter: 7,

      restaurantFilter: "restaurant",

      requestIdSearch: "",

      pagination: {
        page: 1,
        totalPages: 10,
        totalCount: 0,
        limit: 0,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      adminAdvertisingDetailDialog:
        this.adminAdvertisingDetailDialogDefaultValue,
    };

    this.inputRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.fetchAdvertisingList();
  }

  fetchAdvertisingList = () => {
    this.setState({
      advertisingList: [
        {
          requestId: "000",
          restaurantName: "Taco Time",
          startDate: new Date("2024-11-13"),
          endDate: new Date("2024-11-13"),
          engagement: {
            views: 13,
            likes: {
              number: 123,
              type: "like",
            },
          },
          revenue: 400,
          status: AdminPromocodesAdvertisingStatus.Active,
        },

        {
          engagement: {
            views: 13,
            likes: {
              number: 123,
              type: "dislike",
            },
          },
          requestId: "001",
          restaurantName: "Taco Time",
          startDate: new Date("2024-11-13"),
          endDate: new Date("2024-11-13"),
          revenue: 400,
          status: AdminPromocodesAdvertisingStatus.InActive,
        },

        {
          requestId: "002",
          restaurantName: "Taco Time",
          startDate: new Date("2024-11-13"),
          revenue: 400,
          endDate: new Date("2024-11-13"),
          engagement: {
            views: 13,
            likes: {
              number: 123,
              type: "like",
            },
          },
          status: AdminPromocodesAdvertisingStatus.Active,
        },

        {
          requestId: "003",
          engagement: {
            views: 13,
            likes: {
              number: 123,
              type: "dislike",
            },
          },
          restaurantName: "Taco Time",
          startDate: new Date("2024-11-13"),
          endDate: new Date("2024-11-13"),
          revenue: 400,
          status: AdminPromocodesAdvertisingStatus.Active,
        },
      ],

      pagination: {
        page: 1,
        totalPages: 1,
        totalCount: 4,
        limit: 1,
      },
    });
  };

  openAdminAdvertisingDetailDialog = (
    advertising: AdminPromocodesAdvertising
  ) => {
    this.setState({
      adminAdvertisingDetailDialog: {
        ...this.state.adminAdvertisingDetailDialog,
        open: true,
        form: advertising,
      },
    });
  };

  closeAdminAdvertisingDetailDialog = () => {
    this.setState({
      adminAdvertisingDetailDialog:
        this.adminAdvertisingDetailDialogDefaultValue,
    });
  };

  changeRestaurantFilter = (value: any) => {
    this.setState({
      restaurantFilter: value,
    });
  };

  changeTimeFilter = (value: any) => {
    this.setState({
      timeFilter: value,
    });
  };

  clearRequestIdSearch = () => {
    this.setState({
      restaurantFilter: "",
    });
  };

  handleSearch = () => {};

  downloadReport = () => {};

  async receive(from: string, message: Message) {
    /* empty */
  }
}

// Customizable Area End
