import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import { TFunction, i18n } from "i18next";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  FeedbackOverview,
  FeedbackOverviewStatus,
} from "../../../components/src/KitchenFeedbackOverviewRow.web";
import { Operation } from "../../../components/src/analytics/CustomAnalyticsOperationMetric.web";
import {
  OperationStatisticsProps,
  OperationStatisticsStatus,
  OperationStatisticsTheme,
} from "../../../components/src/analytics/CustomAnalyticsOperationStatistics.web";

export enum TABS {
  SALES = 0,
  OPERATIONS = 1,
  CUSTOMERS = 2,
}

export enum SUB_TABS {
  SALES = 0,
  ORDERS = 1,
}

export interface DailyAverage {
  date: string;
  news: number;
  games: number;
  social: number;
}

export interface AnalyticsSalesRevenue {
  name: string;
  revenue: number;
}

export interface AnalyticsSalesMenuItem {
  id: string;
  menuItem: string;
  percentage: number;
  subtotal: number;
}

export interface AnalyticsSalesPerHour {
  time: Date;
  value: number;
}

export interface AnalyticsSalesCompare {
  date: string;
  current: number;
  period: number;
}

export interface OperationOrder {
  id: string;
  orderId: string;
  compensation: string;
  reason: string;
  itemDetails: string;
  amount: number;
  category: string;
  notes: string;
  createdAt: Date;
}

export interface PopularFoodProps {
  name: string;
  value: number;
  color: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: TFunction<"translation", undefined>;
  i18n: i18n;
  classes: ClassNameMap<any>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];
  filterTime: string;
  txtInputValue: string;

  users: {
    id: string;
    name: string;
    avatar?: string;
    userId: string;
  }[];

  feedbackOverviewData: FeedbackOverview[];

  operationMetric: Operation[];

  operationOrders: OperationOrder[];

  dailyAverageData: DailyAverage[];

  analyticsSalesRevenue: AnalyticsSalesRevenue[];

  analyticsSalesMenuItems: AnalyticsSalesMenuItem[];

  analyticsSalesPerHours: AnalyticsSalesPerHour[];

  analyticsSalesCompareData: AnalyticsSalesCompare[];

  operationStatisticsTotalOrder: OperationStatisticsProps;
  operationStatisticsTotalSales: OperationStatisticsProps;
  operationStatisticsTotalPending: OperationStatisticsProps;
  popularFood: PopularFoodProps[];

  previousPeriod: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  filterTimeList = [
    { id: "0", name: "Weekly", value: "w" },
    { id: "1", name: "Monthly", value: "m" },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabActive: TABS.SALES,
      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.OPERATIONS, name: "Operations" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],
      filterTime: "m",

      txtInputValue: "",
      users: [],
      feedbackOverviewData: [],
      operationMetric: [],
      operationOrders: [],
      dailyAverageData: [],
      analyticsSalesRevenue: [],
      analyticsSalesMenuItems: [],
      analyticsSalesPerHours: [],
      analyticsSalesCompareData: [],

      operationStatisticsTotalOrder: {
        theme: OperationStatisticsTheme.Blue,
        statistics: {
          name: "Total Order",
          number: 0,
          percent: 0,
          status: OperationStatisticsStatus.Down,
        },
      },

      operationStatisticsTotalSales: {
        theme: OperationStatisticsTheme.Red,
        statistics: {
          name: "Total Sales",
          number: 0,
          percent: 0,
          status: OperationStatisticsStatus.Up,
        },
      },

      operationStatisticsTotalPending: {
        theme: OperationStatisticsTheme.Green,
        statistics: {
          name: "Total Pending",
          number: 0,
          percent: 0,
          status: OperationStatisticsStatus.Normal,
        },
      },

      popularFood: [],

      previousPeriod: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  getDir = () => {
    return this.isRTL() ? "rtl" : "ltr";
  };

  isRTL = () => {
    return this.props.i18n.language === "ar";
  };

  getReportTitle = () => {
    return this.props.t(this.state.tabs[this.state.tabActive].name);
  };

  togglePreviousPeriod = () => {
    this.setState({
      previousPeriod: !this.state.previousPeriod,
    });
  };

  fetchUsers = () => {
    this.setState({
      users: [
        {
          id: "1",
          name: "John Doe",
          avatar: "",
          userId: "u123",
        },
        {
          id: "2",
          name: "Jane Smith",
          userId: "u124",
        },
        {
          id: "3",
          name: "Alice Johnson",
          avatar: "",
          userId: "u125",
        },
        {
          id: "4",
          name: "Bob Brown",
          userId: "u126",
        },
        {
          id: "5",
          name: "Charlie Davis",
          avatar: "",
          userId: "u127",
        },
      ],
    });
  };

  fetchFeedbackOverviewData = () => {
    this.setState({
      feedbackOverviewData: [
        {
          id: "1",
          name: "Satisfied",
          data: 75,
          status: FeedbackOverviewStatus.UP,
        },
        {
          id: "2",
          name: "unsatisfied",
          data: 60,
          status: FeedbackOverviewStatus.DOWN,
        },
        {
          id: "3",
          name: "No Rating",
          data: 85,
          status: FeedbackOverviewStatus.UP,
        },
      ],
    });
  };

  fetchOperationMetric = () => {
    this.setState({
      operationMetric: [
        { date: "Apr 30", successful: 2800, unsuccessful: 5600 },
        { date: "May 1", successful: 3000, unsuccessful: 5800 },
        { date: "May 2", successful: 3100, unsuccessful: 5700 },
        { date: "May 3", successful: 3200, unsuccessful: 5900 },
        { date: "May 4", successful: 3300, unsuccessful: 6000 },
        { date: "May 5", successful: 3400, unsuccessful: 6100 },
        { date: "May 6", successful: 3000, unsuccessful: 5800 },
        { date: "May 7", successful: 3200, unsuccessful: 5600 },
        { date: "May 8", successful: 3100, unsuccessful: 5900 },
        { date: "May 9", successful: 3000, unsuccessful: 5700 },
        { date: "May 10", successful: 3300, unsuccessful: 5800 },
        { date: "May 11", successful: 3200, unsuccessful: 6000 },
        { date: "May 12", successful: 3100, unsuccessful: 5700 },
        { date: "May 13", successful: 3300, unsuccessful: 5900 },
        { date: "May 14", successful: 3400, unsuccessful: 6200 },
        { date: "May 15", successful: 3200, unsuccessful: 6000 },
        { date: "May 16", successful: 3100, unsuccessful: 5800 },
        { date: "May 17", successful: 3000, unsuccessful: 5900 },
        { date: "May 18", successful: 3200, unsuccessful: 5700 },
        { date: "May 19", successful: 3100, unsuccessful: 5600 },
        { date: "May 20", successful: 3300, unsuccessful: 5800 },
        { date: "May 21", successful: 3100, unsuccessful: 5900 },
        { date: "May 22", successful: 3000, unsuccessful: 6000 },
        { date: "May 23", successful: 3400, unsuccessful: 6100 },
        { date: "May 24", successful: 3300, unsuccessful: 5900 },
        { date: "May 25", successful: 3200, unsuccessful: 5700 },
        { date: "May 26", successful: 3000, unsuccessful: 5800 },
        { date: "May 27", successful: 3100, unsuccessful: 5600 },
        { date: "May 28", successful: 3300, unsuccessful: 6000 },
        { date: "May 29", successful: 3400, unsuccessful: 6100 },
        { date: "May 30", successful: 3200, unsuccessful: 5800 },
      ],
    });
  };

  fetchDailyAverageData = () => {
    this.setState({
      dailyAverageData: [
        { date: "M", news: 2800, games: 5600, social: 2800 },
        { date: "T", news: 3000, games: 5800, social: 2800 },
        { date: "W", news: 3100, games: 5700, social: 2800 },
        { date: "T", news: 3200, games: 5900, social: 2800 },
        { date: "F", news: 3300, games: 6000, social: 2800 },
        { date: "S", news: 3400, games: 6100, social: 2800 },
        { date: "S", news: 3000, games: 5800, social: 2800 },
      ],
    });
  };

  fetchOperationOrders = () => {
    this.setState({
      operationOrders: [
        {
          id: "1",
          orderId: "723343081",
          compensation: "Partial Refund",
          reason: "Incorrect Item Delivered",
          itemDetails: "Pizza - Medium, Coke - 500ml",
          amount: 25.5,
          category: "Food",
          notes: "Customer received the wrong items; issued partial refund.",
          createdAt: new Date(),
        },
        {
          id: "2",
          orderId: "723343082",
          compensation: "Full Refund",
          reason: "Order Canceled by Restaurant",
          itemDetails: "Burger - Large, Fries - Large",
          amount: 15.0,
          category: "Food",
          notes: "Order was canceled; issued full refund.",
          createdAt: new Date(),
        },
        {
          id: "3",
          orderId: "723343083",
          compensation: "Discount",
          reason: "Late Delivery",
          itemDetails: "Sushi Platter",
          amount: 10.0,
          category: "Food",
          notes: "Customer received a discount due to late delivery.",
          createdAt: new Date(),
        },
        {
          id: "4",
          orderId: "723343084",
          compensation: "Voucher",
          reason: "Missing Item",
          itemDetails: "Pasta, Garlic Bread",
          amount: 5.0,
          category: "Food",
          notes: "Issued a voucher for missing garlic bread.",
          createdAt: new Date(),
        },
        {
          id: "5",
          orderId: "723343085",
          compensation: "Replacement",
          reason: "Damaged Packaging",
          itemDetails: "Salad - Caesar, Juice - Orange",
          amount: 8.75,
          category: "Food",
          notes: "Replaced damaged items free of charge.",
          createdAt: new Date(),
        },
      ],
    });
  };

  fetchAnalyticsSalesRevenue = () => {
    this.setState({
      analyticsSalesRevenue: [
        { name: "Monday", revenue: 3000 },
        { name: "Tuesday", revenue: 4000 },
        { name: "Wednesday", revenue: 2000 },
        { name: "Thursday", revenue: 5000 },
        { name: "Friday", revenue: 4000 },
        { name: "Saturday", revenue: 2000 },
        { name: "Sunday", revenue: 3000 },
      ],
    });
  };

  changeFilterTime
 = (filterTime: string) => {
    this.setState({
      filterTime,
    });
  };

  fetchPopularFood = () => {
    this.setState({
      popularFood: [
        {
          name: "chicken",
          value: 2300,
          color: "#344BFD",
        },

        {
          name: "Indian",
          value: 1900,
          color: "#F4A79D",
        },

        {
          name: "Asian",
          value: 5500,
          color: "#059669",
        },

        {
          name: "meals",
          value: 5300,
          color: "#FF6666",
        },
      ],
    });
  };

  fetchAnalyticsSalesMenuItems = () => {
    this.setState({
      analyticsSalesMenuItems: [
        {
          id: "1",
          menuItem: "Cheeseburger",
          percentage: 15,
          subtotal: 1500,
        },
        {
          id: "2",
          menuItem: "Veggie Pizza",
          percentage: 20,
          subtotal: 2000,
        },
        {
          id: "3",
          menuItem: "Chicken Wings",
          percentage: 10,
          subtotal: 1000,
        },
        {
          id: "4",
          menuItem: "Pasta Alfredo",
          percentage: 12,
          subtotal: 1200,
        },
        {
          id: "5",
          menuItem: "Caesar Salad",
          percentage: 8,
          subtotal: 800,
        },
        {
          id: "6",
          menuItem: "Grilled Salmon",
          percentage: 18,
          subtotal: 1800,
        },
        {
          id: "7",
          menuItem: "Tacos",
          percentage: 10,
          subtotal: 1000,
        },
        {
          id: "8",
          menuItem: "Chocolate Cake",
          percentage: 7,
          subtotal: 700,
        },
      ],
    });
  };

  fetchAnalyticsSalesPerHours = () => {
    this.setState({
      analyticsSalesPerHours: [
        {
          time: new Date(),
          value: 0,
        },
        {
          time: new Date(),
          value: 0,
        },
        {
          time: new Date(),
          value: 0,
        },
        {
          time: new Date(),
          value: 0,
        },
      ],
    });
  };

  fetchAnalyticsSalesCompareData = () => {
    this.setState({
      analyticsSalesCompareData: [
        {
          date: "10",
          current: 2900,
          period: 2400,
        },
        {
          date: "11",
          current: 3000,
          period: 2500,
        },
        {
          date: "12",
          current: 3200,
          period: 2700,
        },
        {
          date: "13",
          current: 3100,
          period: 2600,
        },
        {
          date: "14",
          current: 3300,
          period: 2800,
        },
      ],
    });
  };

  async componentDidMount(): Promise<void> {
    this.fetchUsers();
    this.fetchFeedbackOverviewData();
    this.fetchOperationMetric();
    this.fetchDailyAverageData();
    this.fetchAnalyticsSalesRevenue();
    this.fetchOperationOrders();
    this.fetchPopularFood();
    this.fetchAnalyticsSalesMenuItems();
    this.fetchAnalyticsSalesPerHours();
    this.fetchAnalyticsSalesCompareData();
  }
  // Customizable Area End
}
